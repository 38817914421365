import request from '@/util/request'

export function getShopViewList(params) {
  return request({
    url: '/ShopView.asmx/GetShopViewList',
    method: 'GET',
    params,
  })
}

export function getShopViewInfo(params) {
  return request({
    url: '/ShopView.asmx/GetShopViewInfo',
    method: 'get',
    params,
  })
}

export function addShopView(params) {
  return request({
    url: '/ShopView.asmx/AddShopView',
    method: 'get',
    params,
  })
}
