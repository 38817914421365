<template>
  <div style="margin: 14px">
    <van-nav-bar title="店铺走访" />
    <van-form @submit="onSubmit">
      <van-field
        v-model="formData.shopName"
        label="店铺名称"
        placeholder="请填写店铺名称"
      />
      <van-field
        label="走访时间"
        clickable
        :value="date"
        placeholder="点击选择走访时间"
        :rules="[{ required: true, message: '请选择时间' }]"
        readonly
        @click="showPicker = true"
      />

      <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker
          v-model="formData.Date_OP"
          type="datetime"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field label="照片上传" readonly />
      <div style="padding: 10px;background: white">
        <upload-file :limit="9" @fetch-data="getFileList" />
      </div>
      <van-field
        v-model="formData.Content"
        rows="4"
        autosize
        label="走访内容"
        type="textarea"
        placeholder="请填写走访内容"
        :rules="[{ required: true, message: '请填写走访内容' }]"
      />
      <van-field
        v-model="formData.Remark"
        rows="4"
        autosize
        label="备注"
        type="textarea"
        placeholder="请填写备注"
      />

      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
  import store from '@/store'
  import moment from 'moment'
  import Base64 from '@/util/Base64'
  import UploadFile from '@/components/UploadFile'
  import { Toast } from 'vant'
  import { addShopView } from '@/api/outDoor/shopView/shopView'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'AdviseAdd',
    components: { UploadFile },
    data() {
      return {
        fileList: [],
        date: moment(new Date()).format('yyyy-MM-DD HH:mm'),
        showPicker: false,
        formData: {
          Shop_ID: 0,
          shopName: '', //店铺名称
          Date_OP: moment(new Date()).format('yyyy-MM-DD HH:mm'),
          Content: '', //内容
          Remark: '', //备注
        },
      }
    },
    created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      this.formData.User_ID = Number(userInfo.User_ID)
      this.formData.Shop_ID = Base64.decode(this.$route.query.shopID)
      this.formData.shopName = Base64.decode(this.$route.query.shopName)
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'addView',
        '城管-店铺走访新增',
        'zzdcg.yy.gov.cn/addView'
      )
    },

    methods: {
      async onSubmit() {
        this.formData.filePath =
          this.fileList.length > 0 ? this.fileList.join('|') : ''
        this.formData.Date_OP = moment(this.formData.Date_OP).format(
          'yyyy-MM-DD HH:mm'
        )
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在提交中...',
          forbidClick: true,
        })
        const { data } = await addShopView(this.formData)
        Toast.clear()
        await this.$router.push({
          path: '/shopView',
          query: {
            shopID: Base64.encode(this.formData.Shop_ID),
          },
        })
      },

      onConfirm(val) {
        this.date = moment(val).format('yyyy-MM-DD HH:mm')
        this.formData.Date_OP = moment(val).format('yyyy-MM-DD HH:mm')
        this.showPicker = false
      },

      getFileList(val) {
        this.fileList = val
      },
    },
  }
</script>

<style scoped></style>
